
import { defineComponent } from 'vue';
import Card from '../../atoms/Card/Card.vue';
import Table from '../../organisms/Table/Table.vue';

export default defineComponent({
  components: {
    'arc-card': Card,
    'arc-table': Table,
  },
  props: {
    title: {
      type: String,
    },
    columnDefinitions: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Object || Array,
      required: true,
    },
  },
});
