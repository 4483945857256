
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Notification from '../../molecules/Notification/Notification.vue';
import InfoCard from '../../molecules/Info-Card/Info-Card.vue';
import ChartCard from '../../molecules/Chart-Card/Chart-Card.vue';
import TableCard from '../../molecules/Table-Card/Table-Card.vue';
import Chart from '../../molecules/Chart/Chart.vue';
import Divider from '../../atoms/Divider/Divider.vue';
import { dateFormatter } from '../../../utilities/Functions/formatting';
import { ColumnDefinition } from '../../../utilities/Types/table.types';

export default defineComponent({
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'communication',
    },
  },
  components: {
    'arc-chart': Chart,
    'arc-info-card': InfoCard,
    'arc-notification': Notification,
    'arc-table-card': TableCard,
    'arc-chart-card': ChartCard,
    'arc-divider': Divider,
  },
  computed: {
    ...mapGetters(['sidebarOpen', 'windowWidth', 'announcements', 'activeAnnouncement', 'tips', 'activeTip', 'activeUpcomingCommunications']),
    infoCardSize(): string {
      let size = 'large';
      if (this.windowWidth < 1345 && this.windowWidth > 1265) {
        size = 'medium';
      }
      if (this.windowWidth <= 1265 && this.windowWidth > 1245) {
        size = 'small';
      }
      if (this.windowWidth <= 1245 && this.windowWidth > 1125) {
        size = 'extra-small';
      }
      if (this.windowWidth <= 1125 && this.windowWidth > 640) {
        size = 'large';
      }
      if (this.windowWidth <= 640 && this.windowWidth > 555) {
        size = 'medium';
      }
      if (this.windowWidth <= 555 && this.windowWidth > 530) {
        size = 'small';
      }
      if (this.windowWidth <= 530) {
        size = 'extra-small';
      }
      if (this.sidebarOpen) {
        size = 'extra-small';
      }
      return size;
    },
    sidebarState(): string {
      let state = 'sidebar-closed';
      if (this.sidebarOpen) {
        state = 'sidebar-open';
      }
      return state;
    },
    firstInfoCardValue(): string {
      let value = '';
      if (this.type === 'communication') {
        value = this.reportData?.activeCommunicationsCount?.toString();
      }
      return value;
    },
    firstInfoCardDescription(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'Active Communications';
      }
      return value;
    },
    firstInfoCardIcon(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'active';
      }
      return value;
    },
    secondInfoCardValue(): string {
      let value = '';
      if (this.type === 'communication') {
        value = this.lastSent ?? '';
      }
      return value;
    },
    secondInfoCardDescription(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'Last Sent Date';
      }
      return value;
    },
    secondInfoCardIcon(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'last-sent';
      }
      return value;
    },
    thirdInfoCardValue(): string {
      let value = '';
      if (this.type === 'communication') {
        value = this.lastEnd ?? '';
      }
      return value;
    },
    thirdInfoCardDescription(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'Last End Dating';
      }
      return value;
    },
    thirdInfoCardIcon(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'last-end';
      }
      return value;
    },
    lastSent(): string | null {
      return this.reportData?.lastSentDate ? dateFormatter(this.reportData?.lastSentDate) : null;
    },
    lastEnd(): string | null {
      return this.reportData?.lastEndDate ? dateFormatter(this.reportData?.lastEndDate) : null;
    },
    chartTitle(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'AVERAGE APPROVAL %';
      }
      return value;
    },
    chartType(): string {
      let value = '';
      if (this.type === 'communication') {
        value = 'line';
      }
      return value;
    },
    chartData() {
      let value: unknown;
      if (this.type === 'communication') {
        value = this.averageApprovalChartData;
      }
      return value;
    },
    upcomingColumnDefinitions(): ColumnDefinition[] | null {
      return [
        {
          type: 'text',
          columnName: 'commId',
          displayName: 'ID',
        },
        {
          type: 'text',
          columnName: 'brand',
          displayName: 'Brand',
        },
        {
          type: 'text',
          columnName: 'name',
          displayName: 'Name',
        },
        {
          type: 'percent',
          columnName: 'approvalAmount',
          displayName: 'Approval',
        },
        {
          type: 'date',
          columnName: 'start',
          displayName: 'Start Date',
        },
        {
          type: 'date',
          columnName: 'end',
          displayName: 'End Date',
        },
      ];
    },
    upcomingTableData(): unknown | null {
      return this.activeUpcomingCommunications ?? null;
    },
    averageApprovalChartData(): unknown | null {
      return this.reportData?.averageApproval ?? null;
    },
    unresponsiveMallsColumnDefinitions(): ColumnDefinition[] | null {
      return [
        {
          type: 'text',
          columnName: 'mall',
          displayName: 'Mall',
        },
        {
          type: 'text',
          columnName: 'activeRetailers',
          displayName: 'Active Retailers',
          position: 'center',
        },
        {
          type: 'bar-chart',
          columnName: 'performance',
          displayName: 'Performance',
          position: 'center',
        },
        {
          type: 'percent',
          columnName: 'approved',
          displayName: 'Approved',
          position: 'center',
        },
      ];
    },
    unresponsiveMallsTableData(): unknown | null {
      return this.reportData?.unresponsiveMalls?.malls ?? null;
    },
    unresponsiveChartData(): unknown | null {
      // to-do: add this to the reports object
      return {
        datasets: [
          {
            label: 'Average',
            data: [10, 40, 50, 20, 25, 15, 60],
            borderColor: '#707070',
            backgroundColor: '#707070',
            fill: true,
          },
        ],
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      };
    },
    unresponsiveChartOptions(): unknown | null {
      return {
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            display: false,
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      };
    },
  },
});
