
import { defineComponent } from 'vue';
import Card from '../../atoms/Card/Card.vue';
import Chart from '../../molecules/Chart/Chart.vue';

export default defineComponent({
  components: {
    'arc-card': Card,
    'arc-chart': Chart,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
    },
    chartOnly: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    created: (): boolean => true,
  },

  methods: {
    chartCreated(): void {
      this.$emit('created');
    },
  },

  computed: {
    options(): unknown {
      if (this.chartOptions) {
        return this.chartOptions;
      }
      let options = this.chartOnly
        ? {
            responsive: true,
            plugins: {
              title: {
                display: false,
              },
              tooltip: {
                mode: 'index',
              },
              legend: {
                display: false,
              },
            },
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          }
        : {
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
              },
              legend: {
                display: false,
              },
            },
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
            },
            scales: {
              x: {
                grid: {
                  display: true,
                  drawBorder: true,
                },
                ticks: {
                  display: true,
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: true,
                  drawBorder: true,
                },
                ticks: {
                  display: true,
                },
              },
            },
          };
      return options;
    },
  },
});
