
import { defineComponent } from 'vue';
import Card from '../../atoms/Card/Card.vue';

export default defineComponent({
  components: {
    'arc-card': Card,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    cardClass(): string {
      return `info-card-${this.size}`;
    },
    iconClass(): string {
      return `info-card-${this.size}__svg`;
    },
    valueClass(): string {
      return `info-card-${this.size}__value`;
    },
    descriptionClass(): string {
      return `info-card-${this.size}__description`;
    },
  },
});
